import React, { useState } from 'react'
import tw, { styled } from 'twin.macro'
import TellowLogo from '../images/logos/tellow/_wordmark.svg'

const Layout = styled.div`
    ${tw`bg-red-500 text-white max-w-7xl h-screen mx-auto px-4 sm:px-6 lg:px-8`}
`

const Container = styled.div`
    ${tw`max-w-lg mx-auto m-auto h-screen items-start justify-center flex flex-col`}

    p {
        ${tw`text-sm leading-relaxed antialiased`}
    }
`

const Title = styled.h1`
    ${tw`text-4xl antialiased tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl mb-4`}
`

const Logo = styled.img`
    ${tw`h-6 mb-6`}
    filter: invert() brightness(100);
`

const copy = {
    english: {
        title: 'Wrong website.',
        body: 'You landed on the website of a Dutch accounting app. Are you sure you are in the right place?',
        link: 'Go to Dutch bookkeeping application anyway.',
    },
    spanish: {
        title: 'Sitio web incorrecto.',
        body: 'Aterrizó en el sitio web de una aplicación de contabilidad holandesa. ¿Estás seguro de que estás en el lugar correcto?',
        link: 'Vaya a la aplicación de contabilidad holandesa de todos modos.',
    },
    dutch: {
        title: '¿Que pasa?',
        body: 'Vanwege overmatig foutief verkeer uit Zuid-Amerika, is deze pagina tot leven geroepen. Het lijkt er op dat je probeert in te loggen via Mexico, Argentinië, of Chili. Neem contact op met support voor meer informatie.',
        link: 'Ga toch door naar Tellow.',
    },
}

export default function Incorrecto() {
    const [lang, setLang] = useState('spanish')

    const Buttons = styled.div`
        ${tw`bg-red-500 flex space-x-6 text-red-100 mb-8 text-xs antialiased font-semibold`}

        button {
            ${tw`appearance-none bg-red-500 border-0 text-white border-b-2 border-transparent transition duration-500 ease-in-out`}
        }
    `

    return (
        <Layout>
            <Container>
                <Logo src={TellowLogo} alt="" />
                <Buttons>
                    <button id="sp" onClick={() => setLang('spanish')} type="button">
                        Español
                    </button>
                    <button id="en" onClick={() => setLang('english')} type="button">
                        English
                    </button>
                    <button id="nl" onClick={() => setLang('dutch')} type="button">
                        Nederlands
                    </button>
                </Buttons>
                <Title>{copy[lang].title}</Title>
                <p>{copy[lang].body}</p>
            </Container>
        </Layout>
    )
}
